import TempPayment from "components/payment/temp-payment.page";
import { Redirect, Router } from "@reach/router";
import React from "react";
import { GatsbySeo } from "gatsby-plugin-next-seo";
import PayOrder from "./pay-order";

const Fi: React.FC = () => {
    return (
        <>
            <GatsbySeo noindex={true} nofollow={true} />
            <Router basepath="/fi">
                <Redirect path="/" from="/" to="/" noThrow={true} />
            </Router>
            <Router basepath="/fi/orders">
                <TempPayment path="/:type/:order_number/:order_id/:payment_request_id" />
                <PayOrder path="/pay" />
            </Router>
        </>
    );
};

export default Fi;
